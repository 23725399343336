<template>
	<div class="RepeatTime">
        <div class="RepeatTime-list" v-if="RepeatValue > 1">
            <label>重复时间</label>
            <div v-if="RepeatValue == 2" class="RepeatTime-list-week">
                <a-checkbox class="week" value="1" :checked="_init_checked(1)" @change="_onRepeatWeekChange">一</a-checkbox>
                <a-checkbox class="week" value="2" :checked="_init_checked(2)" @change="_onRepeatWeekChange">二</a-checkbox>
                <a-checkbox class="week" value="3" :checked="_init_checked(3)" @change="_onRepeatWeekChange">三</a-checkbox>
                <a-checkbox class="week" value="4" :checked="_init_checked(4)" @change="_onRepeatWeekChange">四</a-checkbox>
                <a-checkbox class="week" value="5" :checked="_init_checked(5)" @change="_onRepeatWeekChange">五</a-checkbox>
                <a-checkbox class="week" value="6" :checked="_init_checked(6)" @change="_onRepeatWeekChange">六</a-checkbox>
                <a-checkbox class="week" value="0" :checked="_init_checked(0)" @change="_onRepeatWeekChange">日</a-checkbox>
            </div>
            <div v-if="RepeatValue == 5" class="RepeatTime-list-week">
                <a-checkbox class="week" value="1" :checked="_init_checked(1)" @change="_onRepeatWeekChange">一</a-checkbox>
                <a-checkbox class="week" value="2" :checked="_init_checked(2)" @change="_onRepeatWeekChange">二</a-checkbox>
                <a-checkbox class="week" value="3" :checked="_init_checked(3)" @change="_onRepeatWeekChange">三</a-checkbox>
                <a-checkbox class="week" value="4" :checked="_init_checked(4)" @change="_onRepeatWeekChange">四</a-checkbox>
                <a-checkbox class="week" value="5" :checked="_init_checked(5)" @change="_onRepeatWeekChange">五</a-checkbox>
                <a-checkbox class="week" value="6" :checked="_init_checked(6)" @change="_onRepeatWeekChange">六</a-checkbox>
                <a-checkbox class="week" value="0" :checked="_init_checked(0)" @change="_onRepeatWeekChange">日</a-checkbox>
            </div>
            <div v-if="RepeatValue == 3" class="RepeatTime-list-month">
                <a-select defaultValue="1日" class="RepeatSelect" @change="_onRepeatMonthChange">
                    <a-select-option v-for="i in 31" :value="i">{{i}}日</a-select-option>
                    <a-select-option value="0">最后一天</a-select-option>
                </a-select>
            </div>
            <div v-if="RepeatValue == 4" class="RepeatTime-list-year">
                <a-select defaultValue="1月" class="RepeatSelect" @change="_onRepeatYearMonthChange">
                    <a-select-option v-for="i in 12" :value="i">{{i}}月</a-select-option>
                </a-select>
                <a-select defaultValue="1日" class="RepeatSelect" @change="_onRepeatYearDayChange">
                    <a-select-option v-for="i in 31" :value="i">{{i}}日</a-select-option>
                </a-select>
            </div>
        </div>
        <div class="RepeatEnd">
            <label>重复结束</label>
            <a-select defaultValue="0" class="RepeatSelect" @change="_onRepeatEndChange">
                <a-select-option value="0">从不结束</a-select-option>
                <a-select-option value="1">选择结束时间</a-select-option>
            </a-select>
            <a-range-picker class="RepeatEndTime" v-if="isSelectTime == 1" @change="_onRepeatEndTimeChange" />
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
	export default{
        name: 'RepeatTime',
		components: {},
		data(){
			return{
                RepeatValue:'',
                isSelectTime:0,
                Week:[],
                YearDay: 1,
                YearMonth: 1,
                MonthDay: 1,
			}
		},
        props: ['Repeat'],
        watch: {
            Repeat: {
              immediate: true,
              handler (val) {
                console.log(val)
                this.RepeatValue = val;
              },
              deep: true
            }
        },
		methods:{
            moment,
            _onRepeatEndChange(value){
                this.isSelectTime = value;
                this.$parent.RepeatType = value;
                if (value == 0) {
                    this.$parent.Week = '';
                    this.$parent.MonthDay = '';
                    this.$parent.YearMonth = '';
                    this.$parent.YearDay = '';
                }
            },
            _onRepeatEndTimeChange(value){
                if (value.length > 0) {
                    this.$parent.RepeatStartTime = moment(value[0]._d).format('YYYY-MM-DD');
                    this.$parent.RepeatEndTime   = moment(value[1]._d).format('YYYY-MM-DD');
                } else {
                    this.$parent.RepeatStartTime = '';
                    this.$parent.RepeatEndTime   = '';
                }
            },
            _onRepeatWeekChange(e){
                if (e.target.checked) {
                    this.Week.push(e.target.value)
                } else {
                    let Weeks = this.Week;
                    for (var i = 0; i < Weeks.length; i++) {
                        if (e.target.value == Weeks[i]) {
                            Weeks.splice(i,1);
                        }
                    }
                    this.Week = Weeks;
                }
                this.$parent.Week = this.Week.toString();
            },
            _onRepeatMonthChange(value){
                this.MonthDay = value;
            },
            _onRepeatYearMonthChange(value){
                this.$parent.YearMonth = value;
            },
            _onRepeatYearDayChange(value){
                this.$parent.YearDay = value;
            },
            _init_checked(value){
                if (this.$parent.Week) {
                    let WeekArr = this.$parent.Week.split(',');
                    if (WeekArr.indexOf(value.toString()) !== -1) {
                        return true;
                    } else {
                        return false;
                    }
                }
                return false;
            },
		}

	}
</script>

<style scoped>
    .RepeatEnd{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .RepeatSelect{
        width:200px;
        margin-left: 22px;
    }
    .RepeatEndTime{
        width:210px;
        margin-left: 10px;
    }
    .RepeatTime-list{
        margin-top: 10px;
    }
    .RepeatTime-list-week{
        width: 400px;
        display: inline-block;
        margin-left: 21px;
    }
    .RepeatTime-list-month{
        display: inline-block;
    }
    .RepeatTime-list-year{
        display: inline-block;
    }
</style>