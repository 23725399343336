<template>
    <div class="Calendar">
    <div id="lnb">
        <div class="lnb-new-schedule">
            <button id="btn-new-schedule" type="button" class="btn btn-default btn-block lnb-new-schedule-btn" @click="onCreateNewSchedule">新建日程</button>
            <button class="btn btn-default btn-block lnb-new-schedule-btn" @click="onCreateFollow">新建关注</button>
        </div>
        <div id="lnb-calendars" class="lnb-calendars">
            <div>
                <div class="lnb-calendars-item">
                    <label>
                        <input class="tui-full-calendar-checkbox-square" type="checkbox" value="me">
                        <span></span>
                        <strong>查看我的</strong>
                    </label>
                </div>
            </div>
            <div id="calendarList" class="lnb-calendars-d1">
                <div class="lnb-calendars-item" v-for="(item, index) in followlist">
                    <label>
                        <input type="checkbox" class="tui-full-calendar-checkbox-round" :value="item.Id">
                        <span :style="{'border-color': item.Color}"></span>
                        <span>{{item.PName}}</span>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div id="right">
        <div id="menu">
            <span class="dropdown">
                <a-select  defaultValue="每月" style="width: 120px;margin-right: 10px;" @change="handleChange">
                    <a-select-option value="day">每日</a-select-option>
                    <a-select-option value="week">每周</a-select-option>
                    <a-select-option value="month">每月</a-select-option>
                </a-select>
            </span>
            <span id="menu-navi" @click="onClickNavi($event)">
                <a-button  style="margin-right: 10px;" data-action="move-today">今天</a-button>
                <a-button  style="margin-right: 10px;" data-action="move-prev">
                    <a-icon type="left"/>
                </a-button>
                <a-button style="margin-right: 10px;" data-action="move-next">
                    <a-icon type="right"/>
                </a-button>
            </span>
            <span id="renderRange" class="render-range">{{dateRange}}</span>
        </div>
        <!-- <div id="calendar"></div> -->
        <calendar style="height: 800px;"
            ref="tuiCal"
            :calendars="calendarList"
            :schedules="scheduleList"
            :view="view"
            :taskView="taskView"
            :scheduleView="scheduleView"
            :theme="theme"
            :week="week"
            :month="month"
            :timezones="timezones"
            :disableDblClick="disableDblClick"
            :isReadOnly="isReadOnly"
            :template="template"
            :useCreationPopup="useCreationPopup"
            :useDetailPopup="useDetailPopup"
            :usageStatistics="usageStatistics"

            @beforeCreateSchedule="onBeforeCreateSchedule"
            @beforeDeleteSchedule="onBeforeDeleteSchedule"
            @beforeUpdateSchedule="onBeforeUpdateSchedule"
            @clickSchedule="onClickSchedule"
        />

        
    </div>

    <div class="shade" style="opacity: .8" @click="onClickShade" v-if="shade"></div>
    <div class="shade" style="opacity: .8" @click="_close_creationpopup" v-if="ScheduleCreationPopup"></div>
    <div class="shade" style="opacity: .8" @click="_close_creationpopup_detail" v-if="ScheduleDetailPopup"></div>

    <div class="follow" v-show="isShowonFollow">
        <div class="follow-title">添加关注</div>
        <div class="follow-item">
            <a-select defaultValue="1" class="followType" @change="onFollowChange">
              <a-select-option value="1">人员</a-select-option>
              <a-select-option value="3">部门</a-select-option>
              <a-select-option value="2">台历</a-select-option>
            </a-select>

            <div style="position: relative;width:300px" v-if="followType != 2">
                <person class="person" v-bind="personObj" @getData="_relevanceObject"></person>
                <a-input v-if="followType == 1" v-model="relevanceText" style="background-color: #f8f8f8;" placeholder="点我选择人员..." read-only/>
                <a-input v-if="followType == 3" v-model="relevanceText" style="background-color: #f8f8f8;" placeholder="点我选择部门..." read-only/>
            </div>

            <a-select v-if="followType == 2" defaultValue="001880b5d0a80b9c5dbb58e4f91f552d" class="followType2" @change="onFollow2Change">
                <a-select-option value="001880b5d0a80b9c5dbb58e4f91f552d">总部会议</a-select-option>
                <a-select-option value="7">培训</a-select-option>
                <a-select-option value="b539aee3975cbe3b26b631e5ac58ad92">小王子上海活动安排</a-select-option>
            </a-select>

        </div>
        <div class="follow-btn">
            <a-button @click="onClickShade">取消</a-button>
            <a-button type="primary" class="follow-save-btn" @click="onFollowSave">保存</a-button>
        </div>
    </div>

    <!--创建弹框内容-->
    <div id="ScheduleCreationPopup" v-if='ScheduleCreationPopup'>
        <div class="tui-full-calendar-popup" style="width:680px;top:20%;left:38%;z-index:100">
            <div class="tui-full-calendar-popup-container">
        
                <!--主题-->
                <div class="tui-full-calendar-popup-section">
                    <label class="label_name">主題</label>
                    <div class="tui-full-calendar-popup-section-item tui-full-calendar-section-title" >
                        <span class="tui-full-calendar-icon tui-full-calendar-ic-title"></span>
                        <a-input class="tui-full-calendar-content" placeholder="事件主题" v-model="Title" />
                    </div>
                </div>


                <div class="tui-full-calendar-popup-section tui-full-calendar-dropdown tui-full-calendar-close tui-full-calendar-select">
                    <label class="label_name">所属台历</label>
                    <div class="tui-full-calendar-popup-section-item">
                        <a-select :defaultValue="_convert_value('Category',Category)" class="Category" @change="_onSelectChange($event,'Category')">
                          <a-select-option value="0">通用</a-select-option>
                          <a-select-option value="1">培训</a-select-option>
                          <a-select-option value="2">总部会议</a-select-option>
                        </a-select>
                    </div>
                </div>

                <div class="tui-full-calendar-popup-section tui-full-calendar-dropdown tui-full-calendar-close tui-full-calendar-select">
                    <label class="label_name">类型</label>
                    <div class="tui-full-calendar-popup-section-item">
                        <a-select :defaultValue="_convert_value('CalendarType',CalendarType)" class="CalendarType" @change="_onSelectChange($event,'CalendarType')">
                          <a-select-option value="0">常规</a-select-option>
                          <a-select-option value="1">日志</a-select-option>
                          <a-select-option value="2">计划</a-select-option>
                          <a-select-option value="3">会议</a-select-option>
                          <a-select-option value="4">外出</a-select-option>
                        </a-select>
                    </div>
                </div>

                <!--时间-->
                <div class="tui-full-calendar-popup-section">
                    <label class="label_name">时间</label>
                    <div class="tui-full-calendar-popup-section-item">
                        <div class="start-date">
                            <a-date-picker v-model="defaultStartDate" class="StartDate" show-time placeholder="开始时间" @change="onStartDateChange" @ok="onStartDateChange" />
                        </div>
                        <span class="tui-full-calendar-section-date-dash">-</span>
                        <div class="end-date">
                            <a-date-picker v-model="defaultEndDate" class="EndDate" show-time placeholder="结束时间" @change="onEndDateChange" @ok="onEndDateChange" />
                        </div>
                        <div class="tui-full-calendar-section-allday" @click="CalendarJs.allday()">
                            <input id="tui-full-calendar-schedule-allday" type="checkbox" class="tui-full-calendar-checkbox-square">
                            <span class="tui-full-calendar-icon tui-full-calendar-ic-checkbox"></span>
                            <span class="tui-full-calendar-content">全天</span>
                        </div>
                    </div>
                </div>

                <div id="tui-full-calendar-popup-more-content" style="display:none">

                    <div class="tui-full-calendar-popup-section">
                        <label class="label_name">参与人</label>
                        <div class="tui-full-calendar-popup-section-item" style="position: relative;background-color:#d9d9d9">
                            <person class="person" v-bind="join_personObj" @getData="_relevanceObject($event,'join')"></person>
                            <a-input class="join-person" v-model="join_relevanceText" placeholder="点击添加参与人" read-only />
                        </div>
                    </div>

                    <div style="position:relative" class="tui-full-calendar-popup-section tui-full-calendar-dropdown tui-full-calendar-close tui-full-calendar-select">
                        <label class="label_name">重复频率</label>
                        <div class="tui-full-calendar-popup-section-item">
                            <a-select :defaultValue="_convert_value('Repeat',Repeat)" class="Repeat" @change="_onSelectChange($event,'Repeat')">
                              <a-select-option value="0">一次性活动</a-select-option>
                              <a-select-option value="1">每天</a-select-option>
                              <a-select-option value="2">每周</a-select-option>
                              <a-select-option value="5">每两周</a-select-option>
                              <a-select-option value="3">每月</a-select-option>
                              <a-select-option value="4">每年</a-select-option>
                            </a-select>
                        </div>
                        <RepeatTime v-if="Repeat > 0" :Repeat="Repeat"></RepeatTime>
                    </div>

                    <div class="tui-full-calendar-popup-section">
                        <label class="label_name">代他人发</label>
                        <div class="tui-full-calendar-popup-section-item" style="position: relative;background-color:#d9d9d9">
                            <person class="person" v-bind="replace_personObj" @getData="_relevanceObject($event,'replace')"></person>
                            <a-input class="replace-person" v-model="replace_relevanceText" placeholder="代他人发" value="" read-only />
                        </div>
                    </div>

                    <div class="tui-full-calendar-popup-section">
                        <label class="label_name">说明</label>
                        <div class="tui-full-calendar-popup-section-textarea tui-full-calendar-popup-section-item">
                            <a-textarea style="width:100%;height:100%" placeholder="说明" :rows="3" v-model="Content" />
                        </div>
                    </div>

                    <div style="margin-top:10px" class="tui-full-calendar-popup-section tui-full-calendar-dropdown tui-full-calendar-close tui-full-calendar-select">
                        <label class="label_name">权限</label>
                        <div class="tui-full-calendar-popup-section-item">
                            <a-select :defaultValue="_convert_value('Authority',Authority)" class="Authority" @change="_onSelectChange($event,'Authority')">
                              <a-select-option value="0">公开</a-select-option>
                              <a-select-option value="1">私人</a-select-option>
                              <a-select-option value="2">参与人可见</a-select-option>
                            </a-select>
                        </div>
                        
                    </div>

                </div>

                <button class="tui-full-calendar-button tui-full-calendar-popup-close" @click="calendar_popup_hide">
                    <span class="tui-full-calendar-icon tui-full-calendar-ic-close"></span>
                </button>

                <div class="tui-full-calendar-section-button-save" style="margin-top:5px;">
                    <button @click="event_save" style="margin-left:10px" class="tui-full-calendar-button tui-full-calendar-confirm tui-full-calendar-popup-save">
                        <span>保存</span>
                    </button>
                    <button class="tui-full-calendar-popup-more" @click="CalendarJs.more()">
                        <span id="tui-full-calendar-popup-more">更多选项</span>
                    </button>
                </div>
            </div>
            <!-- <div id="tui-full-calendar-popup-arrow" class="tui-full-calendar-popup-arrow tui-full-calendar-arrow-bottom">
                <div class="tui-full-calendar-popup-arrow-border">
                    <div class="tui-full-calendar-popup-arrow-fill"></div>
                </div>
            </div> -->
        </div>
    </div>

    <div id="ScheduleDetailPopup" style="display:none">
        <ScheduleDetailPopupTempl :TemplData="TemplData"></ScheduleDetailPopupTempl>
    </div>
</div>
</template>

<script>
import 'css/calendar/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import 'css/calendar/default.css';
import 'css/calendar/icons.css';
import moment from 'moment'
import { Calendar } from '@toast-ui/vue-calendar';
import theme from './theme';

var user = JSON.parse(window.sessionStorage.getItem('userInfo'));
import person from 'components/SelectPersonnel/SelectPersonnel'; //选人
import ScheduleDetailPopupTempl from './template/ScheduleDetailPopup';
import RepeatTime from './template/RepeatTime';
import CalendarJs from './Calendar';

import _ScheduleCreationPopup from "./js/ScheduleCreationPopup";
import _ScheduleDetailPopup from "./js/scheduleDetailPopup";

const today = new Date();

const getDate = (type, start, value, operator) => {
  start = new Date(start);
  type = type.charAt(0).toUpperCase() + type.slice(1);
  if (operator === '+') {
    start[`set${type}`](start[`get${type}`]() + value);
  } else {
    start[`set${type}`](start[`get${type}`]() - value);
  }
  return start;
};

export default {
    name: 'Calendar',
    components: {
        'calendar': Calendar,
        person,
        ScheduleDetailPopupTempl,
        RepeatTime
    },
    data() {
        return {
            ShowPersonState:false,
            isReadonly:true,
            ScheduleCreationPopup:false,
            ScheduleDetailPopup:false,
            calendarList: [],
            scheduleList: [
                // {
                //     id: '1',
                //     calendarId: '1',
                //     title: '测试机一号',
                //     category: 'time',
                //     start: '2021-08-18 10:30:00',
                //     end: '2021-08-19 12:30:00',
                //     raw: {
                //         category:'通用',
                //         calendartype: '常规',
                //         repeat: '一次性活动',
                //         authority: '公开',
                //         subtitle:'谭湘桂 创建于 2021-08-12 09:50:53',
                //     }
                // },
                // {
                //     id: '2',
                //     calendarId: '1',
                //     title: '测试机二号',
                //     category: 'time',
                //     start: '2021-08-19 12:30:00',
                //     end: '2021-08-19 17:30:00',
                //     raw:{
                //         category: '通用',
                //         calendartype: '常规',
                //         repeat: '每天',
                //         authority: '公开',
                //         subtitle:'谭湘桂 创建于 2021-08-12 09:50:53',
                //     }
                // }
            ],
            view: 'month',
            taskView: false,
            scheduleView: true,
            theme: theme,
            week: {
                narrowWeekend: true,
                showTimezoneCollapseButton: true,
                timezonesCollapsed: false,
                daynames: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
            },
            month: {
                // visibleWeeksCount: 6,
                // startDayOfWeek: 1,
                daynames: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
            },
            timezones: [],
            disableDblClick: true,
            isReadOnly: false,
            template: {
                milestone: function(schedule) {
                    return '<span class="calendar-font-icon ic-milestone-b"></span> <span style="background-color: ' + schedule.bgColor + '">' + schedule.title + '</span>';
                },
                popupDetailBody: function(schedule) {
                    return 'Body : ' + schedule.body;
                },
            },
            useCreationPopup: false,
            useDetailPopup: false,
            usageStatistics:false,
            isShowonFollow:false,
            shade:false,
            dateRange:'',
            followType:1,
            relevanceText: '',
            join_relevanceText:'',
            replace_relevanceText:'',
            personObj: {
                role: 0,
                positions: 0,
                rank: 0,
                nums: 10000, //选的数量
                department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                contacts: '', //常用联系人 不要常用联系人传空''
                selectArr: {}//要传入的数据
            },

            //参与人参数
            join_personObj: {
                role: 0,
                positions: 1,
                rank: 0,
                nums: 10000, //选的数量
                department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                contacts: '', //常用联系人 不要常用联系人传空''
                selectArr: {}//要传入的数据
            },

            //代发参数
            replace_personObj: {
                role: 0,
                positions: 0,
                rank: 0,
                nums: 1, //选的数量
                department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
                contacts: '', //常用联系人 不要常用联系人传空''
                selectArr: {}//要传入的数据
            },

            relevanceObject: '',
            followlist:'',
            startDate:'',
            endDate:'',
            SetId:'me',
            _OrgData:'',
            Taili:'',
            TailiText:'',
            CalendarJs:CalendarJs,
            DateTimeFormat:'YYYY-MM-DD HH:mm:ss',

            defaultStartDate:'',
            defaultEndDate:'',

            OldStartDate:'',
            OldEndDate:'',

            Category:0,
            CalendarType:0,
            Repeat:0,
            Authority:0,
            Content:'',
            Title:'',
            UserInfo:'',
            LoanerId:'',
            LoanerName:'',
            isAllday:false,
            _StartTime:'',
            _EndTime:'',
            Week:'',
            YearDay: 1,
            YearMonth: 1,
            MonthDay: 1,
            RepeatType: 0,
            RepeatStartTime: '',
            RepeatEndTime: '',
            EventStartDate:'',
            EditType: '',
            TaskId: '',
            IsUpload: '',


            isCreateScheduleClick:false,
            TemplData:'',
            CurrentScheduleId:'',

            container:null,
        }
    },
    mounted(){
        this.init();

        CalendarJs.set(this);

        $('#lnb-calendars').on('change', CalendarJs.onChangeCalendars);

        this._getData();
    },
    created(){
        this._getFollowList();
    },
    watch: {

    },
    methods: {
        moment,
        _relevanceObject (list,func = '') {
            let self = this
            let {functions} = func; 
            if (list.length > 0) {
                self._OrgData = JSON.stringify(list);
            } else {
                self._OrgData = '';
            }
            console.log(list)
            let relevanceText = '';
            this.relevanceObject = list;
            for (let i = 0; i < list.length; i++) {
                if (relevanceText != '') {
                  relevanceText += ',';
                }
                relevanceText += list[i].title;
            }
            if (func == 'join') {
                self.UserInfo = JSON.stringify(list);
                self.join_relevanceText = relevanceText;
            } else if(func == 'replace'){
                self.replace_relevanceText = relevanceText;
                self.LoanerId = list[0].id;
                self.LoanerName = list[0].title;
            } else {
                self.relevanceText = relevanceText;
            }
        },
        init() {
            this.container = document.getElementsByClassName('tui-full-calendar-layout')[0];
            this.setRenderRangeText();
        },
        _isEdit(){
            return this.CurrentScheduleId ? true : false;
        },
        setDateTime(startTime,endTime){
            this.defaultStartDate = moment(new Date(startTime));
            this.defaultEndDate = moment(new Date(endTime));
            this._StartTime = moment(this.defaultStartDate).format(this.DateTimeFormat);
            this._EndTime = moment(this.defaultEndDate).format(this.DateTimeFormat);
        },
        onAfterRenderSchedule(e) {
            
        },
        onBeforeCreateSchedule(e) {
            this.isCreateScheduleClick = true;
            this._open_popup(
                e.start._date,
                e.end._date
            );
        },
        onBeforeDeleteSchedule(e) {
            let self = this;
            let data = {
                Pid:e.schedule.id,
                Type:0,
                TheDate:moment(e.schedule.start).format('YYYY-MM-DD')
            };

            self.$axios.post(8000122, data,res => {
                if (res.data.code == 1) {
                    self._getData();
                } else {
                    self.$message.error(res.data.message);
                }
            });
        },
        onBeforeUpdateSchedule(e) {
            let data = e.schedule;
            let raw = data.raw;
            let start = moment(e.start._date).format(this.DateTimeFormat);
            let end = moment(e.end._date).format(this.DateTimeFormat);
            let schedule = this.scheduleList;
            let self = this;

            if (start == data.raw.start) {
                console.log(1111);

                return;
            }

            data.start = start;
            data.end = start;
            data.raw.start = start;
            data.raw.end = end;

            self.$axios.post(8000126, {
                event_id: data.id,
                start: start,
                end: end
            },res => {
                console.log(res);
            });

            console.log(e);
            self.$refs.tuiCal.invoke('updateSchedule',data.id,data.calendarId,data);
        },
        onClickDayname(e) {
            // implement your code
        },
        onClickSchedule(e) {
            // implement your code
            console.log(e.schedule);
            
            let self = this;
            let raw = e.schedule.raw;
            let schedule = e.schedule;
            
            let data = {
                StartDate: raw.start,
                EndDate: raw.end,
                Id: schedule.id,
                Action: 'detail',
                IsTask: raw.istask || ''
            };
            console.log(data);
            // return;
            self.$axios.get(8000123, data,res => {
                if (res.data.code == 1) {
                    let info = res.data.data.info;
                    self.Title = info.Title;
                    self.Category = info.Category;
                    self.CalendarType = info.CalendarType;
                    self.Repeat = info.Repeat;
                    self.Authority = info.Authority;
                    self.Content = info.Content;
                    self.LoanerId = info.LoanerId;
                    self.LoanerName = info.LoanerName;
                    self.YearDay = info.Category;
                    self.YearMonth = info.Category;
                    self.MonthDay = info.Category;
                    self.RepeatType = info.RepeatType;
                    self.RepeatStartTime = info.RepeatStartTime;
                    self.RepeatEndTime = info.RepeatEndTime;
                    self.Week = info.Week ? info.Week.toString() : '';
                    schedule.Templ = res.data.data.template;
                    schedule.raw = info;
                    schedule.isReadOnly = (user.uid == info.CreateUserId || user.uid == info.LoanerId) ? true : false;
                    self.TemplData = schedule;
                    self.CurrentScheduleId = info.Id;
                    console.log(schedule);


                    self._open_detail();

                    setTimeout(function(){
                        let layerContainer = document.getElementsByClassName('tui-full-calendar-popup-detail')[0];
                        console.log(layerContainer);
                        let _ScheduleDetailPopup_ = new  _ScheduleDetailPopup(self.container,layerContainer);
                        _ScheduleDetailPopup_.render(e);
                        
                    },100);
                }
            });
        },
        onClickTimezonesCollapseBtn(e) {
            // implement your code
        },
        handleChange(value){
            this.view = value;
            this.setRenderRangeText();
            this._getData();
        },
        onCreateFollow(){
            this.isShowonFollow = true;
            this.shade = true;
            this.followType = 1;
        },
        _open_popup(start,end){
            this.ScheduleCreationPopup = true;
            this.setDateTime(start,end);
            CalendarJs.setAllDayState(false);
            this._close_creationpopup_detail();
        },
        _open_detail(){
            this.ScheduleDetailPopup = true;
        },
        _init_value(){
            this.Category = 0,
            this.CalendarType = 0;
            this.Repeat = 0;
            this.Authority = 0;
            this.Content = '';
            this.Title = '';
            this.UserInfo = '';
            this.LoanerId = '';
            this.LoanerName = '';
            this.isAllday = false;
            this._StartTime = '';
            this._EndTime = '';
        },
        onClickShade(){
            this.isShowonFollow = false;
            this.shade = false;
            this.relevanceObject = '';
            this.relevanceText = '';
            this.join_relevanceText = '';
            this.replace_relevanceText = '';
            this.CurrentScheduleId = '';
        },
        _close_creationpopup(){
            this.ScheduleCreationPopup = false;
            this.isCreateScheduleClick = false;
            this._init_value();
            CalendarJs.hide();
            this.$refs.tuiCal.invoke('render');
        },
        _close_creationpopup_detail(){
            let self = this;
            this.ScheduleDetailPopup = false;
            let layerContainer = document.getElementsByClassName('tui-full-calendar-popup-detail');
            if (layerContainer.length > 0) {
                if (!this.ScheduleCreationPopup) {
                    this._init_value();
                }
                document.getElementById('ScheduleDetailPopup').appendChild(layerContainer[0]);
            }
        },
        setRenderRangeText() {
            const {invoke} = this.$refs.tuiCal;
            const view = invoke('getViewName');
            const calDate = invoke('getDate');
            const rangeStart = invoke('getDateRangeStart');
            const rangeEnd = invoke('getDateRangeEnd');
            let year = calDate.getFullYear();
            let month = calDate.getMonth() + 1;
            let date = calDate.getDate();

            let dateRangeText = '';
            let endMonth, endDate, start, end, _start, _end;
            switch (view) {
                case 'month':
                    dateRangeText = `${year}-${month}`;
                    _start = rangeStart.getFullYear() + '-' + (rangeStart.getMonth()+1) + '-' + rangeStart.getDate();
                    _end = rangeEnd.getFullYear() + '-' + (rangeEnd.getMonth()+1) + '-' + rangeEnd.getDate();
                    this.startDate = moment(new Date(_start)).format('YYYY-MM-DD');
                    this.endDate = moment(new Date(_end)).format('YYYY-MM-DD');
                break;
                case 'week':
                    year = rangeStart.getFullYear();
                    month = rangeStart.getMonth() + 1;
                    date = rangeStart.getDate();
                    
                    _start = year + '-' + month + '-' + date;

                    endMonth = rangeEnd.getMonth() + 1;
                    endDate = rangeEnd.getDate();
                    start = `${year}-${month}-${date}`;
                    end = `${endMonth}-${endDate}`;

                    _end = year + '-' + endMonth + '-' + endDate;

                    dateRangeText = `${start} ~ ${end}`;

                    this.startDate = moment(new Date(_start)).format('YYYY-MM-DD');
                    this.endDate = moment(new Date(_end)).format('YYYY-MM-DD');
                break;
                default:
                    let dateStr = year + '-' + month + '-' + date;
                    this.startDate = moment(new Date(dateStr)).format('YYYY-MM-DD');
                    this.endDate = this.startDate;
                    dateRangeText = `${year}-${month}-${date}`;
            }
            this.dateRange = dateRangeText;
        },
        onClickNavi(event) {
            if (event.target.tagName === 'BUTTON') {
                const {target} = event;
                let action = target.dataset ? target.dataset.action : target.getAttribute('data-action');
                action = action.replace('move-', '');
                this.$refs.tuiCal.invoke(action);
                this.setRenderRangeText();

                this._getData();
            }
        },
        onCreateNewSchedule(event) {
            console.log(event)
            if (this.useCreationPopup) {
                var start = event.start ? new Date(event.start.getTime()) : new Date();
                var end = event.end ? new Date(event.end.getTime()) : moment().add(1, 'hours').toDate();
                this.$refs.tuiCal.invoke('openCreationPopup',{
                    start: start,
                    end: end
                })
            } else {
                this._open_popup(
                    moment().startOf('day'),
                    moment().endOf('day')
                );
            }
        },
        onFollowChange(value){
            this.followType = value;
            this.relevanceObject = '';
            this.relevanceText = '';
            if (value == 1) {
                this.personObj.department = 0;
            } else if (value == 3) {
                this.personObj.department = 2;
            }
        },
        onFollow2Change(value){
            this.Taili = value;
            this.TailiText = document.getElementsByClassName('followType2')[0].innerText;
        },
        onFollowSave(){
            let self = this;

            if (self.followType == 1 || self.followType == 3) {
                if (this.relevanceText == '') {
                    self.$message.error('请选择需要关注的对象!');
                    return;
                }
            }

            let data = {
                Type: self.followType,
                OrgData: self._OrgData,
                Taili: self.Taili,
                TailiText: self.TailiText
            }

            self.$axios.post(8000120, data,res => {
                if (res.data.code == 1) {
                    self.onClickShade();
                    self._getFollowList();
                    self.$message.success(res.data.message);
                } else {
                    self.$message.error(res.data.message);
                }
            });
        },
        _getData(){
            let self = this;
            this.$message.loading('拉取数据中...')
            self.$axios.get(8000118, {
                SetId:this.SetId,
                start:this.startDate,
                end:this.endDate
            },res => {
                if (res.data.code == 1) {
                    self.scheduleList = res.data.data.data;
                    CalendarJs.setCalendar(self.scheduleList);
                }
                this.$message.destroy()
            });
        },
        _getFollowList(){
            let self = this;
            self.$axios.get(8000119, {},res => {
                self.followlist = res.data.data;
            });
        },
        calendar_popup_hide(){
            this._close_creationpopup();
        },
        _onSelectChange(value,params){
            switch (params) {
                case 'Category':
                    this.Category = value;
                    break;
                case 'CalendarType':
                    this.CalendarType = value;
                    break;
                case 'Repeat':
                    this.Repeat = value;
                    if (value == 0) {
                        this.Week = '';
                        this.YearDay = '';
                        this.YearMonth = '';
                        this.MonthDay = '';
                        this.RepeatType = '';
                        this.RepeatStartTime = '';
                        this.RepeatEndTime = '';
                        this.EventStartDate = '';
                    }
                    break;
                case 'Authority':
                    this.Authority = value;
                    break;
            }
        },
        onStartDateChange(value){
            if (value) {
                this._StartTime = moment(value).format(this.DateTimeFormat);
            } else {
                this._StartTime = moment(this.defaultStartDate).format(this.DateTimeFormat);
            }
        },
        onEndDateChange(value){
            if (value) {
                this._EndTime = moment(value).format(this.DateTimeFormat);
            } else {
                this._EndTime = moment(this.defaultEndDate).format(this.DateTimeFormat);
            }
        },
        event_save(){
            let self = this;

            if (self.Title == '') {
                self.$message.error('主题不能为空');
                return;
            }

            if (!self._StartTime || !self._EndTime) {
                self.$message.error('时间不允许为空');
                return;
            }

            self.isAllday = document.getElementById('tui-full-calendar-schedule-allday').checked;
            let _StartTime = moment(self._StartTime);
            let _EndTime = moment(self._EndTime);
            let UrlCode;

            let data = {
                Title: self.Title,
                Type: Number(self.isAllday) ? 0 : 1,
                StartDate: _StartTime.format('YYYY-MM-DD'),
                StartHour: _StartTime.get('hour'),
                StartMin: _StartTime.get('minute'),
                EndDate: _EndTime.format('YYYY-MM-DD'),
                EndHour: _EndTime.get('hour'),
                EndMin: _EndTime.get('minute'),
                UserInfo: self.UserInfo,
                Repeat: self.Repeat,
                Content: self.Content,
                CalendarType: self.CalendarType,
                Authority: self.Authority,
                Category: self.Category,
                YearDay: self.YearDay,
                YearMonth: self.YearMonth,
                MonthDay: self.MonthDay,
                RepeatType: self.RepeatType,
                RepeatStartTime: self.RepeatStartTime,
                RepeatEndTime: self.RepeatEndTime,
                EventStartDate: _StartTime.format('YYYY-MM-DD'),
                Week: self.Week,
                Id: self.CurrentScheduleId,
                EditType: self.EditType,
                LoanerId: self.LoanerId,
                LoanerName: self.LoanerName,
                TaskId: self.TaskId,
                IsUpload: self.IsUpload
            };

            if (self._isEdit()) {
                UrlCode = 8000124;
            } else {
                UrlCode = 8000121;
            }

            self.$axios.post(UrlCode, data,res => {
                if (res.data.code == 1) {
                    this._close_creationpopup();
                    self._getData();
                } else {
                    self.$message.error(res.data.message);
                }
            });
        },
        _setAllday(o){
            let start,end;
            if (o == 0) {
                start = this.OldStartDate;
                end   = this.OldEndDate;
            } else {
                this.OldStartDate = this.defaultStartDate;
                this.OldEndDate = this.defaultEndDate;
                start = moment().startOf('day');
                end   = moment().endOf('day');
            }
            this.setDateTime(start,end);
        },
        _convert_value(str,value){
            let valueArr;
            switch (str) {
                case 'Category':
                    valueArr = ['通用','培训','总部会议'];
                    break;
                case 'CalendarType':
                    valueArr = ['常规','日志','计划','会议','外出'];
                    break;
                case 'Repeat':
                    valueArr = ['一次性活动','每天','每周','每月','每年','每两周'];
                    break;
                case 'Authority':
                    valueArr = ['公开','私人','参与人可见'];
                    break;
            }

            return valueArr[value];
        },
        _getEventDetail(id,start,end,IsTask){
            let data = {
                StartDate: start,
                EndDate: end,
                Id: id,
                Action: 'detail',
                IsTask: IsTask
            };
            this.$axios.get(8000123, data,res => {
                console.log(res.data)
                // if (res.data.code == 1) {
                    
                // }
            });
        },
    }
}
</script>

<style>
    .Category,.CalendarType,.Authority,.Repeat{
        width:200px;
    }
    .StartDate,.EndDate{
        width:100%;
    }
    .tui-full-calendar-section-start-date,.tui-full-calendar-section-end-date{
        width:200px;
    }
    .join-person,.replace-person{
        width:100%;
        background-color:#d9d9d9;
    }
    .start-date,.end-date{
        display: inline-block;
        border: 1px solid #d5d5d5;
        width:200px;
    }
</style>

