
var CalendarThis;
var CalendarList = [];

function findCalendar(id) {
    var found;

    CalendarList.forEach(function(data) {
        if (data.id === id) {
            found = data;
        }
    });

    return found || CalendarList[0];
}

function refreshScheduleVisibility() {
    var calendarElements = Array.prototype.slice.call(document.querySelectorAll('#calendarList input'));

    CalendarList.forEach(function(data) {
        CalendarThis.$refs.tuiCal.invoke('toggleSchedules',data.id, !data.checked, false);
    });

    CalendarThis.$refs.tuiCal.invoke('render',true);

    calendarElements.forEach(function(input) {
        var span = input.nextElementSibling;
        span.style.backgroundColor = input.checked ? span.style.borderColor : 'transparent';
    });
}

var Calendar = {
	set: function(object){
		CalendarThis = object;
	},
	setCalendar: function(data){
		CalendarList = data;
	},
	more: function(){
		var more_node = document.getElementById('tui-full-calendar-popup-more');
		var content_node = document.getElementById('tui-full-calendar-popup-more-content');
		if (content_node.style.display == 'none') {
			more_node.innerHTML = '收起更多选项';
        	content_node.style.display = 'block';
		} else {
			more_node.innerHTML = '更多选项';
        	content_node.style.display = 'none';
		}
	},
	hide: function(){
		var more_node = document.getElementById('tui-full-calendar-popup-more');
		var content_node = document.getElementById('tui-full-calendar-popup-more-content');
		if (content_node.style.display == 'block') {
			more_node.innerHTML = '更多选项';
        	content_node.style.display = 'none';
		}
	},
	allday: function(){
		var allday_node = document.getElementById('tui-full-calendar-schedule-allday');
		allday_node.checked = !allday_node.checked;
		if (allday_node.checked) {
			CalendarThis._setAllday(1);
		} else {
			CalendarThis._setAllday(0);
		}
	},
	setAllDayState: function(checked){
		let _state = document.getElementById('tui-full-calendar-schedule-allday');
		if (_state) {
			_state.checked = checked;
		}
	},
	onChangeCalendars: function(e){
		var calendarId = e.target.value;
	    var checked = e.target.checked;
	    var viewAll = document.querySelector('.lnb-calendars-item input');
	    var calendarElements = Array.prototype.slice.call(document.querySelectorAll('#calendarList input'));
	    var allCheckedCalendars = true;

	    if (calendarId === 'all') {
	        allCheckedCalendars = checked;

	        calendarElements.forEach(function(input) {
	            var span = input.parentNode;
	            input.checked = checked;
	            span.style.backgroundColor = checked ? span.style.borderColor : 'transparent';
	        });
	    } else if(calendarId === 'me'){
	        calendarElements.forEach(function(input) {
	            var span = input.nextElementSibling;
	            input.checked = false;
	            span.style.backgroundColor = 'transparent';
	        });
	        CalendarThis.SetId = 'me';
	    } else {
	    	viewAll.checked = false;	        
	        var span = e.target.nextElementSibling;
	        if (checked) {
	        	CalendarThis.SetId = calendarId;
	        	span.style.backgroundColor = span.style.borderColor;
	        } else {
	        	span.style.backgroundColor = 'transparent';
	        }

	        calendarElements.forEach(function(input) {
		        var span = input.nextElementSibling;
		        if (input.value !== calendarId) {
		        	if (input.checked) {
		        		input.checked = false;
		        	}
		        	span.style.backgroundColor = 'transparent';
		        }
		    });
	    }

	    CalendarThis._getData();
	},
};

export default Calendar;