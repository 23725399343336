var View = require('tui-calendar/src/js/view/view');
var FloatingLayer = require('tui-calendar/src/js/common/floatingLayer');
var util = require('tui-code-snippet');
var DatePicker = require('tui-date-picker');
var timezone = require('tui-calendar/src/js/common/timezone');
var config = require('tui-calendar/src/js/config');
var domevent = require('tui-calendar/src/js/common/domevent');
var domutil = require('tui-calendar/src/js/common/domutil');
var common = require('tui-calendar/src/js/common/common');
var datetime = require('tui-calendar/src/js/common/datetime');
var TZDate = timezone.Date;
var MAX_WEEK_OF_MONTH = 6;
var cssPrefix = 'tui-full-calendar-';

var ScheduleCreationPopup = {
	_getcssPrefix(str){
		return cssPrefix + str;
	},
	initialize: function(event){
		this.guide = event.guide;
		this.container = document.getElementsByClassName('tui-full-calendar-layout')[0];
		this.layer = new FloatingLayer(null, this.container);

		this._onClickListeners = [
	        this._closePopup.bind(this),
	    ];

		domevent.on(this.container, 'click', this._onClick, this);
	},
	_getGuideElements: function(guide) {
	    var guideElements = [];
	    var i = 0;

	    if (guide.guideElement) {
	        guideElements.push(guide.guideElement);
	    } else if (guide.guideElements) {
	        for (; i < MAX_WEEK_OF_MONTH; i += 1) {
	            if (guide.guideElements[i]) {
	                guideElements.push(guide.guideElements[i]);
	            }
	        }
	    }

	    return guideElements;
	},
	_setPopupPositionAndArrowDirection: function(){
		var getGuideElements = this._getGuideElements(this.guide)[0];

		var layer = document.getElementsByClassName(this._getcssPrefix('popup'))[0];
		console.log(layer);
	    var layerSize = {
	        width: layer.offsetWidth,
	        height: layer.offsetHeight
	    };
	    console.log(layerSize);
	    var containerBound = this.container.getBoundingClientRect();

	    var pos = this._calcRenderingData(layerSize, containerBound, getGuideElements.getBoundingClientRect());

	    domutil.setPosition(layer, pos.x, pos.y);

	    this._setArrowDirection(pos.arrow);
	},
	_calcRenderingData: function(layerSize, containerBound, guideBound){
		var yPosInfo = this._getYAndArrowDirection(
	        guideBound.top,
	        guideBound.bottom,
	        layerSize.height,
	        containerBound.top,
	        containerBound.bottom
	    );
	    var xPosInfo = this._getXAndArrowLeft(
	        guideBound.left,
	        guideBound.right,
	        layerSize.width,
	        containerBound.left,
	        containerBound.right
	    );

	    /**
	     * @typedef {Object} PopupRenderingData
	     * @property {number} x - left position
	     * @property {number} y - top position
	     * @property {string} arrow.direction - direction of popup arrow
	     * @property {number} [arrow.position] - relative position of popup arrow, if it is not set, arrow appears on the middle of popup
	     */
	    return {
	        x: xPosInfo.x,
	        y: yPosInfo.y,
	        arrow: {
	            direction: yPosInfo.arrowDirection,
	            position: xPosInfo.arrowLeft
	        }
	    };
	},
	_getYAndArrowDirection: function(
		guideBoundTop,
    	guideBoundBottom,
    	layerHeight,
    	containerTop,
    	containerBottom
	) {
		var arrowDirection = 'arrow-bottom';
	    var MARGIN = 3;
	    var y = guideBoundTop - layerHeight;
	    console.log(y)
	    console.log(containerTop)
	    console.log(layerHeight)

	    if (y < containerTop) {
	        y = guideBoundBottom - containerTop + MARGIN;
	        arrowDirection = 'arrow-top';
	    } else {
	        y = y - containerTop - MARGIN;
	    }

	    if (y + layerHeight > containerBottom) {
	        y = containerBottom - layerHeight - containerTop - MARGIN;
	    }
	    // console.log(y);
	    /**
	     * @typedef {Object} YAndArrowDirection
	     * @property {number} y - top position of popup layer
	     * @property {string} [arrowDirection] - direction of popup arrow
	     */
	    return {
	        y: y,
	        arrowDirection: arrowDirection
	    };
	},
	_getXAndArrowLeft: function (
		guideBoundLeft,
    	guideBoundRight,
    	layerWidth,
    	containerLeft,
    	containerRight
	) {
		var guideHorizontalCenter = (guideBoundLeft + guideBoundRight) / 2;
	    var x = guideHorizontalCenter - (layerWidth / 2);
	    var ARROW_WIDTH_HALF = 10;
	    var arrowLeft;

	    if (x + layerWidth > containerRight) {
	        x = guideBoundRight - layerWidth + ARROW_WIDTH_HALF;
	        arrowLeft = guideHorizontalCenter - x;
	    } else {
	        x += ARROW_WIDTH_HALF;
	    }

	    if (x < containerLeft) {
	        x = 0;
	        arrowLeft = guideHorizontalCenter - containerLeft - ARROW_WIDTH_HALF;
	    } else {
	        x = x - containerLeft - ARROW_WIDTH_HALF;
	    }

	    /**
	     * @typedef {Object} XAndArrowLeft
	     * @property {number} x - left position of popup layer
	     * @property {numbe3er} arrowLeft - relative position of popup arrow, if it is not set, arrow appears on the middle of popup
	     */
	    return {
	        x: x,
	        arrowLeft: arrowLeft
	    };
	},
	_setArrowDirection: function(arrow) {
		console.log(arrow)
	    var direction = arrow.direction || 'arrow-bottom';
	    var arrowEl = domutil.get(this._getcssPrefix('popup-arrow'));
	    var borderElement = document.getElementsByClassName(this._getcssPrefix('popup-arrow-border'))[0];

	    if (direction !== 'arrow-bottom') {
	        domutil.removeClass(arrowEl, this._getcssPrefix('arrow-bottom'));
	        domutil.addClass(arrowEl, this._getcssPrefix(direction));
	    }

	    if (arrow.position) {
	        borderElement.style.left = arrow.position + 'px';
	    }
	},
	_onClick: function(clickEvent){
		var target = domevent.getEventTarget(clickEvent);

	    util.forEach(this._onClickListeners, function(listener) {
	        return !listener(target);
	    });
	},
	_closePopup: function(target) {
		
	    var className = this._getcssPrefix('popup-close');
	    // console.log(domutil.hasClass(target, className));
	    this.hide();
	    // if (domutil.hasClass(target, className) || domutil.closest(target, '.' + className)) {

	    //     this.hide();

	    //     return true;
	    // }

	    return true;
	},
	hide: function() {
	    this.layer.hide();

	    if (this.guide) {
	        this.guide.clearGuideElement();
	        this.guide = null;
	    }

	    domevent.off(document.body, 'mousedown', this._onMouseDown, this);
	},
	_onMouseDown: function(mouseDownEvent) {
	    var target = domevent.getEventTarget(mouseDownEvent),
	        popupLayer = domutil.closest(target, this._getcssPrefix('floating-layer'));

	    if (popupLayer) {
	        return;
	    }

	    this.hide();
	},
};

export default ScheduleCreationPopup;