<template>
	<div v-if="schedule.Templ == 'event_detail'" class="tui-full-calendar-popup tui-full-calendar-popup-detail">
      <div class="tui-full-calendar-popup-container">
        <div class="tui-full-calendar-popup-section tui-full-calendar-section-header">
          <div>
            <span class="tui-full-calendar-schedule-private tui-full-calendar-icon tui-full-calendar-ic-private"></span>
            <span class="tui-full-calendar-schedule-title">{{info.Title}}</span>
          </div>
          <div class="tui-full-calendar-popup-detail-date tui-full-calendar-content">{{info.CreateUserName}} 创建于 {{info.CreateTime}}</div>
        </div>

        <div class="tui-full-calendar-section-detail">
            <div class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">所属台历  {{info.CategoryName}}</span>
            </div>
            <div class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">开始时间  {{info.StartTime}}</span>
            </div>
            <div class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">结束时间  {{info.EndTime}}</span>
            </div>
            <div v-if="info.UserName" class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">共享人  {{info.UserName}}</span>
            </div>
            <div v-if="info.LoanerName" class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">由Ta代发  {{info.LoanerName}}</span>
            </div>
            <div v-if="info.Content" class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">说明  {{info.Content}}</span>
            </div>
            <div v-if="info.Repeat" class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">重复  {{info.Digest}}</span>
            </div>
        </div>

        <div v-if="schedule.isReadOnly" class="tui-full-calendar-section-button">
           <button class="tui-full-calendar-popup-edit" @click="_clickEdit">
                <span class="tui-full-calendar-icon tui-full-calendar-ic-edit"></span>
                <span class="tui-full-calendar-content">编辑</span>
            </button>
            <div class="tui-full-calendar-popup-vertical-line"></div>
            <button class="tui-full-calendar-popup-delete">
                <span class="tui-full-calendar-icon tui-full-calendar-ic-delete"></span>
                <a-popconfirm title="确认要删除吗？" ok-text="确认" cancel-text="取消" @confirm="_del_confirm" @cancel="_del_cancel">
                    <span class="tui-full-calendar-content">删除</span>
                </a-popconfirm>
            </button>
        </div>
      </div>
      <div class="tui-full-calendar-popup-top-line" :style="{'background-color': bgColor}"></div>
      <div id="tui-full-calendar-popup-arrow" class="tui-full-calendar-popup-arrow tui-full-calendar-arrow-left">
        <div class="tui-full-calendar-popup-arrow-border">
            <div class="tui-full-calendar-popup-arrow-fill"></div>
        </div>
      </div>
    </div>

    <div v-else-if="schedule.Templ == 'event_task'" class="tui-full-calendar-popup tui-full-calendar-popup-detail">
      <div class="tui-full-calendar-popup-container">
        <div class="tui-full-calendar-popup-section tui-full-calendar-section-header">
          <div>
            <span class="tui-full-calendar-schedule-private tui-full-calendar-icon tui-full-calendar-ic-private"></span>
            <span class="tui-full-calendar-schedule-title">{{info.Name}}{{info.LeaveType ? '->'+info.LeaveType : ''}}</span>
          </div>
          <div v-if="info.CreateUserName" class="tui-full-calendar-popup-detail-date tui-full-calendar-content">{{info.CreateUserName}} 创建于 {{info.CreateTime}}</div>
        </div>

        <div class="tui-full-calendar-section-detail">
            <div v-if="info.ProjectName" class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">所属台历  {{info.ProjectName}}</span>
            </div>
            <div v-if="info.Hours" class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">时长  {{info.Hours}} 小时</span>
            </div>
            <div v-if="info.StartDate" class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">开始时间  {{info.StartDate}}</span>
            </div>
            <div v-if="info.PlanEndDate" class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">结束时间  {{info.PlanEndDate}}</span>
            </div>
            <div v-if="info.UserNames" class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">负责人  {{info.UserNames}}</span>
            </div>
            <div v-if="info.Content" class="tui-full-calendar-popup-detail-item tui-full-calendar-popup-detail-item-separate">
                <span class="tui-full-calendar-content">详情  {{info.Content}}</span>
            </div>
        </div>
      </div>
      <div class="tui-full-calendar-popup-top-line" :style="{'background-color': bgColor}"></div>
      <div id="tui-full-calendar-popup-arrow" class="tui-full-calendar-popup-arrow tui-full-calendar-arrow-left">
        <div class="tui-full-calendar-popup-arrow-border">
            <div class="tui-full-calendar-popup-arrow-fill"></div>
        </div>
      </div>
    </div>
</template>

<script>
    import moment from 'moment';
    var user = JSON.parse(window.sessionStorage.getItem('userInfo'));
	export default{
		components: {},
		data(){
			return{
                schedule:'',
                info:'',
                bgColor:'#a1b56c'
			}
		},
        props: ['TemplData'],
        watch: {
            TemplData: {
              immediate: true,
              handler (val) {
                this.schedule = '';
                this.info = '';
                this.bgColor = '#a1b56c';
                
                this.schedule = val;
                this.info = val.raw;
                this.bgColor = val.bgColor ? val.bgColor : '#a1b56c';
                // console.log(this.schedule);
              },
              deep: true
            }
        },
        mounted(){

        },
		methods:{
            _clickEdit(){
                this.$parent._open_popup(this.schedule.start,this.schedule.end);
            },
            _clickDel(){

            },
            _del_confirm(){
                let self = this;

                let data = {
                    Pid: this.schedule.id,
                    Type: 0,
                    TheDate: this.schedule.raw.EventStartDate
                };

                // self.$parent.$refs.tuiCal.invoke('deleteSchedule',{
                //     scheduleId: this.schedule.id,
                //     calendarId: this.schedule.calendarId,
                //     silent: false
                // });

                self.$axios.post(8000122, data,res => {
                    if (res.data.code == 1) {
                        self.$parent._close_creationpopup_detail();
                        self.$parent._getData();
                    } else {
                        self.$message.error(res.data.message);
                    }
                });
            },
            _del_cancel(){
                console.log('取消了');
            },
            _toArr(obj){
                var arr = [];
                for (let i in obj) {
                    arr[i] = obj[i];
                }
                return arr;
            },
		}

	}
</script>