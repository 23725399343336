export default {
  'common.border': '1px solid #ddd',
  'common.backgroundColor': 'white',
  'common.holiday.color': '#f54f3d',
  'common.saturday.color': '#135de6',
  'common.dayname.color': '#333',
  'common.today.color': '#009688',

  // creation guide style
  'common.creationGuide.backgroundColor': 'rgba(19, 93, 230, 0.1)',
  'common.creationGuide.border': '1px solid #135de6',
};